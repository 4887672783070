<template>
    <front-layout>
        <div class="flex-wrapper-full-background">
            <div class="intro mx-auto">
                <v-container>
                    <h1 class="text-center mb-11" v-text="'API Pricing'"/>
                    <v-row v-if="!$_.isEmpty(prices)" justify="center">
                        <v-col sm="10" md="7" lg="6">
                            <v-card width="100%">
                                <v-card-title class="justify-center" v-text="'Prepaid packages'"/>
                                <v-card-text>
                                    <p class="text-center minutes_summary" v-text="model.minutes"/>
                                    <p class="text-center" v-text="'conversion minutes'"/>
                                    <p class="text-center price_summary">${{ prices[model.minutes]}}</p>
                                    <p class="text-center">{{ ((prices[model.minutes] * 10) / model.minutes).toFixed(2) }} cents per minute</p>
                                    <vue-slider
                                        tooltip="none"
                                        :adsorb="true"
                                        :data="Object.keys(prices)"
                                        v-model="model.minutes"/>
                                    <div class="text-center card_row">
                                        <strong v-text="'Unlimited*'"/> file size
                                    </div>
                                    <div class="text-center card_row">
                                        <strong v-text="'Unlimited'"/> maximum conversion time per file
                                    </div>
                                    <div class="text-center card_row">
                                        <strong v-text="'Unlimited'"/> concurrent conversions
                                    </div>
                                    <div class="text-center card_row">
                                        <strong v-text="'High'"/> priority
                                    </div>
                                    <div class="text-center card_row">
                                        No automatic renewal
                                    </div>
                                </v-card-text>

                                <v-card-actions>
                                    <v-btn @click="submit" block color="primary" v-text="buttonTitle"/>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>

        <v-dialog v-model="checkoutDialog" max-width="600" persistent>
            <stripe-checkout-card @submit-card="subscribe"/>
        </v-dialog>
    </front-layout>
</template>

<script>
import FrontLayout from "@/layouts/FrontLayout";
import VueSlider from 'vue-slider-component'
import BillingApi from "@/api/BillingApi";

import 'vue-slider-component/theme/default.css'
import PricePlan from "../../models/PricePlan";
import StripeCheckoutCard from "../../components/StripeCheckoutCard";
// import { mapMutations } from "vuex";

export default {
    name: 'ApiPricing',
    components: {StripeCheckoutCard, FrontLayout, VueSlider},
    data() {
        return {
            model: {
                minutes: 1000,
                plan: {}
            },
            plans: [],
            prices: {},
            checkoutDialog: false,
        }
    },
    mounted() {
        this.setPlans()
    },
    computed: {
        user() {
            return this.$auth.user()
        },
        buttonTitle() {
            return this.$auth.user()
                ? 'Buy'
                : 'Sign up'
        },
        // ...mapMutations(['showSnackBar']),
    },
    methods: {
        async setPlans() {
            this.plans = await PricePlan
                .where('scopes', 'api')
                .where('is_active', true)
                .get();

            this.plans.forEach(plan => {
                this.$set(this.prices, +(plan.name), plan.amount);
            })

            // Set default plan
            this.$nextTick(() => this.model.minutes = 5000)
        },
        getCheckoutModal() {
            this.checkoutDialog = true;
        },
        submit() {
            if (!this.$auth.user()) {
                this.signUp()
                return null;
            } else {
                this.getCheckoutModal()
            }

            // BillingApi.updateSubscription(method, this.pricePlan)
            //     .then(async () => {
            //         this.showSnackBar({color: 'success', timeout: 3000, text: 'Subscription confirmed'})
            //         await this.$auth.fetch();
            //         await this.$router.push({name: 'dashboard.index'})
            //     })
            //
            // let method = {};
            //
            // BillingApi.updateSubscription(method, this.model.plan.stripe_id)
        },
        signUp() {
            this.$router.push({name: 'sign-up', params: {pricePlan: this.model.plan.stripe_id}})
        },
        subscribe(method) {
            BillingApi.updateSubscription(method, this.model.plan.stripe_id)
                .then(async () => {
                    // this.showSnackBar({color: 'success', timeout: 3000, text: 'Subscription confirmed'})
                    await this.$auth.fetch();
                    await this.$router.push({name: 'dashboard.index'})
                })
        }
    },
    watch: {
        'model.minutes': function (val) {
            this.model.plan = this.plans.find(item => item.name == val)
        }
    }
}
</script>

<style lang="scss" scoped>
.card_row {
    border-bottom: 1px solid var(--v-secondary-lighten5);
    padding: .5rem 0;
}
.minutes_summary {
    font-size: 2rem;
    font-weight: bold;
}

.price_summary {
    font-size: 1.5rem;
    font-weight: bold;
}
</style>
